<template>
  <v-container> Projects </v-container>
</template>

<script>
export default {
  name: 'Projects',

  data: () => ({}),

  watch: {},

  computed: {},

  methods: {}
}
</script>

<style scoped>
</style>
